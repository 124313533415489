import _defineProperty from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
import React, { useEffect, useRef } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import styles from './RichText.style.js';
import Image from '../Image';
import { parseRichText } from '../../../utils/utilityFunction';
var RichText = function RichText(_ref) {
  var jsonContent = _ref.jsonContent,
    richTextEmbeddedAsset = _ref.richTextEmbeddedAsset,
    ContainerElem = _ref.ContainerElem,
    inheritedStyles = _ref.inheritedStyles,
    className = _ref.className,
    removeEmptyParagraph = _ref.removeEmptyParagraph,
    isHero = _ref.isHero,
    contentfulLivePreview = _ref.contentfulLivePreview,
    shouldBeTabbable = _ref.shouldBeTabbable;
  var richTextContainer = useRef(null);
  useEffect(function () {
    var timer;
    if (richTextContainer !== null && richTextContainer.current !== null) {
      if (isHero && typeof shouldBeTabbable !== 'undefined') {
        timer = setTimeout(function () {
          giveAnchorsATitle(richTextContainer.current, shouldBeTabbable);
        }, 0);
      } else {
        timer = setTimeout(function () {
          giveAnchorsATitle(richTextContainer.current);
        }, 0);
      }
    }
    return function () {
      clearTimeout(timer);
    };
  }, [shouldBeTabbable, isHero, richTextContainer]);
  var giveAnchorsATitle = function giveAnchorsATitle(element, isTabbable) {
    if (element.nodeName == 'A') {
      element.title = element.innerText ? element.innerText : 'rich-text anchor';
      if (isHero && typeof isTabbable !== 'undefined') {
        element.tabIndex = isTabbable ? '0' : '-1';
        element.ariaHidden = isTabbable ? 'false' : 'true';
      }
    }
    var elArr = element.querySelectorAll('a');
    var _iterator = _createForOfIteratorHelper(elArr),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var anchorElem = _step.value;
        anchorElem.title = anchorElem.innerText ? anchorElem.innerText : 'rich-text anchor';
        if (anchorElem.getAttribute('href') && anchorElem.attributes && anchorElem.attributes['href'] && anchorElem.attributes['href'].nodeValue && (anchorElem.attributes['href'].nodeValue.includes('http:') || anchorElem.attributes['href'].nodeValue.includes('https:'))) {
          anchorElem.target = '_blank';
          anchorElem.rel = 'noopener noreferrer';
        } else {
          anchorElem.target = '_self';
        }
        if (isHero && typeof isTabbable !== 'undefined') {
          anchorElem.tabIndex = isTabbable ? '0' : '-1';
          anchorElem.ariaHidden = isTabbable ? 'false' : 'true';
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  };
  if (richTextEmbeddedAsset === undefined) {
    var htmlContent = parseRichText(jsonContent, isHero, removeEmptyParagraph);
    var content = {
      __html: htmlContent
    };
    return __jsx(ContainerElem, _extends({
      ref: richTextContainer,
      dangerouslySetInnerHTML: content,
      className: "nva-rich-text ".concat(className)
    }, contentfulLivePreview));
  } else {
    return __jsx("div", _extends({
      ref: richTextContainer,
      className: "nva-rich-text ".concat(className)
    }, contentfulLivePreview), documentToReactComponents(jsonContent, {
      renderNode: _defineProperty({}, BLOCKS.EMBEDDED_ASSET, function (node) {
        if (Object.keys(richTextEmbeddedAsset).length !== 0) {
          return richTextEmbeddedAsset.map(function (item) {
            var contentType = item.contentType;
            var contentGroup = contentType.split('/')[0];
            if (contentGroup === 'video') {
              var url = item.url,
                fileName = item.fileName,
                sys = item.sys;
              if (node.data.target.sys.id === sys.id) {
                return __jsx("video", {
                  key: sys.id,
                  controls: true
                }, __jsx("source", {
                  src: url,
                  alt: fileName
                }));
              }
            }
            if (contentGroup === 'image') {
              var _url = item.url,
                _fileName = item.fileName,
                _sys = item.sys;
              if (node.data.target.sys.id === _sys.id) {
                return __jsx(Image, {
                  src: _url,
                  alt: _fileName,
                  title: _fileName,
                  key: _sys.id,
                  inheritedStyles: inheritedStyles,
                  className: className
                });
              }
            }
          });
        }
      })
    }));
  }
};
RichText.defaultProps = {
  inheritedStyles: '',
  ContainerElem: 'div'
};
export default styled(RichText).withConfig({
  componentId: "sc-wturor-0"
})(["", ";"], styles);
export { RichText as RichTextVanilla };